<template>
  <div v-if="$isLoading">
    <div class="min-vh-100 position-relative">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">
            เงื่อนไขสำหรับขนส่ง
          </h1>
        </b-col>
      </CRow>

      <b-container class="container-box bg-white px-4 py-4 mt-4">
        <b-row>
          <b-col md="6">
            <InputSelect
              class="mb-4"
              title="ขนส่ง"
              name="shipping"
              v-bind:options="paymentChannelList"
              valueField="id"
              textField="name"
            />
          </b-col>
          <b-col md="6">
            <InputSelect
              class="mb-4"
              title="ประเภทเงื่อนไข"
              name="condition"
              v-bind:options="conditionList"
              valueField="id"
              textField="name"
              v-model="condition"
            />
          </b-col>
        </b-row>
        <b-row class="no-gutters">
          <b-col>
            <b-table
              responsive
              class="text-center table-list"
              striped
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <template v-slot:cell(startWeight)="data">
                <b-form-input
                  :class="['input-serach']"
                  placeholder="กรอกราคา"
                  type="number"
                ></b-form-input>
              </template>
              <template v-slot:cell(endWeight)="data">
                <b-form-input
                  :class="['input-serach']"
                  placeholder="กรอกราคา"
                  type="number"
                ></b-form-input>
              </template>
              <template v-slot:cell(shippingPrice)="data">
                <b-form-input
                  :class="['input-serach']"
                  placeholder="กรอกราคา"
                  type="number"
                ></b-form-input>
              </template>
              <template v-slot:cell(id)="data">
                <div
                  class="d-flex justify-content-center"
                  v-if="items.length > 1"
                >
                  <b-button
                    variant="link"
                    class="text-dark px-1 py-0"
                    @click="deleteCondition(data.index)"
                  >
                    ลบ
                  </b-button>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row
          class="d-flex justify-content-center align-items-center mb-4 mt-2"
        >
          <b-button class="button btn-cancel ml-2" @click="addCondition()"
            >เพิ่มเงื่อนไข</b-button
          >
        </b-row>
        <hr />
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            md="6"
            class="form-inline footer-paginate justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <label class="font-weight-bold main-label">
                การใช้งาน
                <span class="text-danger">*</span>
              </label>
              <div>
                <b-form-checkbox
                  switch
                  class="radio-active ml-3"
                  size="lg"
                  v-model="enabled"
                >
                  <span class="ml-2 main-label">
                    {{ enabled ? "เปิด" : "ปิด" }}
                  </span>
                </b-form-checkbox>
              </div>
            </div>
          </b-col>
          <b-col md="6" class="d-flex justify-content-end align-items-center">
            <router-link :to="'/shipping'">
              <b-button class="button btn-cancel ml-2"
                >ย้อนกลับ</b-button
              ></router-link
            >
            <b-button class="btn-main ml-3" @click="checkForm()"
              >บันทึก</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- Modal -->
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import DateTimePicker from "@/components/inputs/DateTimePicker";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import InputSelect from "@/components/inputs/InputSelect";

import { required } from "vuelidate/lib/validators";

export default {
  name: "shippingDetial",
  components: {
    DateTimePicker,
    ModalAlertError,
    ModalAlertConfirm,
    ModalLoading,
    ModalAlert,
    InputSelect,
  },
  data() {
    return {
      id: this.$route.params.id,
      modalMessage: "",
      showModal: false,
      isBusy: false,
      enabled: true,
      fields: [
        {
          key: "startWeight",
          label: "นำ้หนักเริ่มต้น(กิโลกรัม)",
          class: "w-100px text-nowrap",
        },
        {
          key: "endWeight",
          label: "นำ้หนักสิ้นสุด(กิโลกรัม)",
          class: "w-100px text-nowrap",
        },
        {
          key: "shippingPrice",
          label: "ค่าขนส่ง(บาท)",
          class: "w-100px text-nowrap",
        },
        { key: "id", label: "", class: "w-100px text-nowrap" },
      ],
      items: [
        {
          startWeight: "",
          endWeight: "",
          shippingPrice: "",
        },
      ],
      conditionList: [
        {
          id: null,
          name: "กรุณาเลือกเงื่อนไข",
        },
        {
          id: 0,
          name: "นำ้หนักเท่านั้น",
        },
        {
          id: 1,
          name: "ขนาดเท่านั้น",
        },
        {
          id: 2,
          name: "เปรียบเทียบแพงสุด",
        },
        {
          id: 3,
          name: "เปรียบเทียบถูกสุด",
        },
      ],
      condition: null
    };
  },
  //   validations() {
  //     return {
  //       form: {
  //         startDate: { required },
  //         endDate: { required },
  //       },
  //       newData: {
  //         $each: {
  //           price: { required },
  //           storePrice: { required },
  //         },
  //       },
  //     };
  //   },
  created: async function() {
    this.$isLoading = false;
    await this.getList();
    this.$isLoading = true;
  },
  methods: {
    getList: async function() {
      this.isBusy = true;
      if (this.id != 0) {
        let data = await this.$callApi(
          "get",
          `${this.$baseUrl}/api/product/GetProductPriceByDate?dateTime=${this.id}`,
          null,
          this.$headers,
          null
        );

        if (data.result == 1) {
          if (data.detail.status == "ดำเนินการ") {
            this.checkStatus = true;
          }

          this.form.startDate = `${data.detail.startDate.split(" ")[0]}T${
            data.detail.startDate.split(" ")[1]
          }.000+07:00`;
          this.form.endDate = `${data.detail.endDate.split(" ")[0]}T${
            data.detail.endDate.split(" ")[1]
          }.000+07:00`;

          this.enabled = data.detail.enabled == 1 ? true : false;

          if (data.detail.products.length > 0) {
            for (const i of data.detail.products) {
              this.newData.push({
                price: i.customPrice,
                storePrice: !i.storePrice ? 0 : i.storePrice,
              });

              let detail = await this.$callApi(
                "get",
                `${this.$baseUrl}/api/product/productDetail/${i.productId}`,
                null,
                this.$headers,
                null
              );

              if (detail.result == 1) {
                let result = detail.detail.product;
                this.productGroups.push({
                  id: result.id,
                  name: result.urlKey,
                  imageUrl: `${this.$imagePath}/${result.imageUrl}`,
                  sku: result.sku,
                  price: result.price,
                });
              }
            }
          }
        }
      }

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/product/List`,
        null,
        this.$headers,
        this.filterProduct
      );

      if (resData.result == 1) {
        this.itemsProduct = resData.detail.dataList;
        this.rowsProduct = resData.detail.count;
      }
      this.isBusy = false;
    },
    addCondition() {
      this.items.push({
        startWeight: "",
        endWeight: "",
        shippingPrice: "",
      });
    },
    deleteCondition(index) {
      this.items.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.input-serach.error {
  border: 1px solid #e55353 !important;
}

.text-error {
  color: #ff0000;
  font-size: 14px;
}

.search-modal {
  border: 2px solid #d0d0d0 !important;
}

.border-danger {
  border: 1px solid #e55353 !important;
}

::v-deep .square-boxs {
  margin: auto;
  width: 75%;
  padding-top: 60%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

::v-deep .modal-body .table-detail {
  min-height: 40rem;
}

.modal-header {
  border: 0 !important;
  padding: 0;
}

.text-text {
  color: #16274a;
  margin-bottom: 0;
  font-size: 26px;
}

.img {
  width: 100px;
}

.modal-body {
  margin: auto;
  text-align: center;
}

@media (max-width: 820px) {
  ::v-deep .modal-dialog {
    max-width: 700px !important;
  }
}
</style>
